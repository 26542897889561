import React from "react"
import {Link, graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import Social from '../components/social';
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import Articles from '../components/articles';

export default function StatePage({data, pageContext}) {
  const sales_taxes = data.allSalestax2021Csv.nodes;
  const income_taxes = data.allIncometax2021Csv.nodes;
  const property_taxes = data.allPropertytax2021Csv.nodes;
  const tax_website = data.allStateTaxWebsitesCsv.nodes;
  const state_rankings = data.allStatetaxrankings2021Csv.nodes;
  const stateName = pageContext.State;
  const currentDate = new Date().getFullYear();
  const image = getImage(data.allImageSharp.nodes[0]);
  const articles = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <SEO title={stateName + " Tax Brackets and Rates " + currentDate } 
      description={ "In " + stateName + ", there's a tax rate of " + income_taxes[0]["Percentage"] + " on the first " + income_taxes[0]["Single___Start"] + " to " + income_taxes[0]["Single___End"] + 
      " of income for single or married filing taxes separately. If you're married filing taxes jointly there's a tax rate of " + income_taxes[0]["Percentage"] + " from " + income_taxes[0]["Married_Filing_Jointly___Start"] + " to " + income_taxes[0]["Married_Filing_Jointly___End"] 
      + ". For those earning more than..."  }/>
      <div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <nav aria-label="breadcrumb">
                <div className="breadcrumb">
                  <div className="breadcrumb-item"><Link to="/">Home</Link></div>
                  <div className="breadcrumb-item active" aria-current="page">{stateName}</div>
                </div>
              </nav>
              <p className="hero-meta">Last updated: {currentDate}</p>
              <h1 className="hero-heading">{stateName} Tax Rate Information</h1>
            </div>
            <div className="col-sm-12 col-md-6 d-md-block">
              <div className="hero-wrapper">
                <GatsbyImage image={image} alt={pageContext.State}/>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-8">
              <Social title={stateName + " Tax Brackets and Rates"} url={"https://taxrateinfo.com"} />
              <hr/>
              <h2>State Income Tax</h2>
              <p>Income tax is a tax that is imposed on people and businesses based on the income or profits that they earned. The tax rates are broken down into groups called <em>tax brackets</em>.
                Income tax brackets are required state taxes in {stateName} based on relative income and earnings.</p>
              {income_taxes[0]["Percentage"] == "none" &&
              (<><p><strong>In {stateName}, no income tax is required</strong>.</p>
                <p>You still are responsible for federal taxes, here are your <a href="/federal-tax-rates-and-tax-brackets/2021">federal tax rates.</a></p></>)
              }

              {income_taxes[0]["Percentage"] !== "none" &&
              <p>{pageContext.State} state income taxes are listed below.  In addition, check out your  <a href="/federal-tax-rates-and-tax-brackets/2021">federal tax rate.</a>
              </p>
              }
              <h3>Tax Brackets</h3>
              <table className="table tax__bracket">
                <tr>
                  <th>Tax Rate</th>
                  <th>Single</th>
                  <th>Married</th>
                </tr>
                <tbody>
                {income_taxes.map(bracket => {
                  const {
                    Percentage,
                    Single___Start,
                    Single___End,
                    Married_Filing_Jointly___Start,
                    Married_Filing_Jointly___End,
                  } = bracket

                  return (
                    <tr key={bracket.uniqueId}>
                      <td>{Percentage}</td>
                      <td>{Single___Start} - {Single___End}</td>
                      <td>{Married_Filing_Jointly___Start} - {Married_Filing_Jointly___End}</td>
                    </tr>
                  )
                })}
                </tbody>
              </table>

              <h3>Deductions and Exemptions</h3>
              <table className="table tax__bracket">
                <tr>
                  <th></th>
                  <th>Standard Deduction</th>
                  <th>Personal Exemption</th>
                  <th>Dependent Exemption</th>
                </tr>
                <tr>
                  <td>Single</td>
                  <td>{income_taxes[0]["Single___Standard_Deduction"]}</td>
                  <td>{income_taxes[0]["Single___Personal_Exemption"]}</td>
                  <td>{income_taxes[0]["Dependent___Personal_Exemption"]}</td>
                </tr>
                <tr>
                  <td>Married</td>
                  <td>{income_taxes[0]["Couple___Standard_Deduction"]}</td>
                  <td>{income_taxes[0]["Couple___Personal_Exemption"]}</td>
                  <td>{income_taxes[0]["Dependent___Personal_Exemption"]}</td>
                </tr>
              </table>

              <h2>Sales Taxes</h2>
              <p>Sales tax, or use tax, is any tax that's imposed by the government for the purchase of goods or services in the state of {stateName}. Any sales tax that is collected belongs to the state and does not belong to the business that was transacted with. </p>
              {sales_taxes[0]["Combined_State_and_Local_Tax"] == "0%" &&
              <p><strong>No sales taxes for purchases are required in {stateName}</strong></p>
            }


              <div className=""></div>

              <table>
                <tr>
                  <td className="scorecard">
              <span className="scorecard-value scorecard-value--success">
                {sales_taxes[0]["State_Sales_Tax_Rate"]}
              </span>
                    <span className="scorecard-label">
                State Taxes
              </span>
                  </td>
                  <td className="scorecard">
              <span className="scorecard-value scorecard-value--success">
              {sales_taxes[0]["Average_Local_Sales_Tax_Rate"]}
              </span>
                    <span className="scorecard-label">
                Average local tax
              </span>
                  </td>
                  <td className="scorecard">
              <span className="scorecard-value scorecard-value--neutral">
              {sales_taxes[0]["Combined_State_and_Local_Tax"]}
              </span>
                    <span className="scorecard-label">
                  Combined Tax
              </span>
                  </td>
                </tr>
              </table>


              <h2>Property Taxes</h2>
              <p>Property taxes are collected by local governments in {stateName} and are usually based on the value of the property. Money that is collected from these taxes is often put back into local communities to help improve the schools, infrastructure, roads,
              and other community and public works projects. The property tax rates listed below are the average for properties in {stateName}. Actual property taxes may vary depending on local geographic factors that affect the property values.</p>
              <table className="scorecard-table scorecard-table--single">
                <tr>
                  <td className="scorecard">
            <span className="scorecard-value scorecard-value--neutral">
            {property_taxes[0]["Average_Property_Tax"]}
            </span>
                    <span className="scorecard-label">
                Combined Tax
            </span>
                  </td>
                </tr>
              </table>
            </div>
            {/* End main column */}
            <div className="col-sm-12 col-md-4">
              <h3>State Tax Rankings</h3>
              <ul className="list-group list-group-flush ranking-group">
                <li className="list-group-item align-items-center">
                  <span className="badge badge-primary badge-pill">{state_rankings[0]["Sales_Tax_Ranking"]}</span> Sales
                  Tax Ranking
                </li>
                <li className="list-group-item align-items-center">
                  <span
                    className="badge badge-primary badge-pill">{state_rankings[0]["Property_Tax_Ranking"]}</span> Property
                  Tax Ranking
                </li>
                <li className="list-group-item align-items-center">
                  <span
                    className="badge badge-primary badge-pill">{state_rankings[0]["Income_Tax_Ranking"]}</span> Income
                  Tax Ranking
                </li>
              </ul>
              <p>
                <small><strong>Ranking Scale</strong><br/> 1 = lowest state tax rate <br/>50 = highest tax rate.</small>
              </p>


              <Articles articles={articles}/>
            </div>
          </div>
          {/* End row */}
        </div>
        {/* End container */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($State: String, $heroImage: String) {
    allImageSharp(filter: {fluid: {originalName: {eq: $heroImage}}}) {
      nodes {
        gatsbyImageData(
         placeholder: BLURRED
         layout: FULL_WIDTH
        )
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
    allStatetaxrankings2021Csv(filter: {State: {eq: $State}}) {
      nodes {
        Sales_Tax_Ranking
        Property_Tax_Ranking
        Income_Tax_Ranking
      }
    }
    allStateTaxWebsitesCsv(filter: {State: {eq: $State}}) {
      nodes {
        Website
      }
    }
    allPropertytax2021Csv(filter: {State: {eq: $State}}) {
      nodes {
        Average_Property_Tax
      }
    }
    allSalestax2021Csv(filter: {State: {eq: $State}}) {
      nodes {
        State_Sales_Tax_Rate
        Combined_State_and_Local_Tax
        Average_Local_Sales_Tax_Rate
      }
    }
    allIncometax2021Csv(filter: {State: {eq: $State}}) {
      nodes {
        Couple___Personal_Exemption
        Couple___Standard_Deduction
        Dependent___Personal_Exemption
        Married_Filing_Jointly___Start
        Married_Filing_Jointly___End
        Percentage
        Single___End
        Single___Personal_Exemption
        Single___Standard_Deduction
        Single___Start
        State
      }
    }
  }
`
