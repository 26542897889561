import React from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share'



import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

const Social = ({ url, title }) => (
  <div>
    {/* Share on social:
    <FacebookShareButton url={url} >
          <FacebookIcon  size={40} round={true}/>
    </FacebookShareButton>

    <TwitterShareButton url={url} title={title} via={'taxrateinfo'} hashtags={'#tax'}>
          <TwitterIcon  size={40} round={true} />
    </TwitterShareButton>

    <LinkedinShareButton url={url} >
      <LinkedinIcon  size={40} round={true}/>
    </LinkedinShareButton> */}
  </div>
);


export default Social
